<template>
  <b-tab
    :title="`Lead`"
    :active="$route.query.tab === 'lead'"
    @click="setQuery('lead')"
  >
    <b-container fluid class="mt-1 wrapper container-user" v-show="!loading">
      <h5 class="my-3">Configuração de lead</h5>
      <div class="container-titulo">
        <label>Temperatura do lead</label>
        <img
          width="17px"
          height="17px"
          src="@/assets/img/icons/help-circle.svg"
          alt="icon"
          v-b-tooltip.hover
          title="Aqui você pode personalizar a pontuação padrão da ação do lead no sistema."
        />
      </div>
      <div class="temperatura">
        <div class="acesso">
          <div class="container-titulo">
            <h4 class="titulo">Acesso</h4>
          </div>
          <input type="number" v-model="acesso" />
        </div>
        <div class="cadastro">
          <div class="container-titulo">
            <h4 class="titulo">Cadastro</h4>
          </div>
          <input type="number" v-model="cadastro" />
        </div>
        <div class="abriu-email">
          <div class="container-titulo">
            <h4 class="titulo">Abriu o e-mail</h4>
          </div>
          <input type="number" v-model="abriu_email" />
        </div>
        <div class="clicou-email">
          <div class="container-titulo">
            <h4 class="titulo">Clicou no e-mail</h4>
          </div>
          <input type="number" v-model="clicou_email" />
        </div>
        <div class="checkbox_options">
          <div class="acao">
            <h4 class="titulo">
              Lead pode se cadastrar mais de uma vez em um formulário
            </h4>
            <b-form-checkbox v-model="status" name="is_main" size="lg" switch>
              <p class="info-checkbox" style="width: 0px">
                {{ status ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
        </div>
        <div class="save_messages">
          <h4 class="titulo">
            Salvar histórico de mensagens de WhatsApp com lead
          </h4>
          <b-form-checkbox
            v-model="save_messages"
            name="is_main"
            size="lg"
            switch
          >
            <p class="info-checkbox" style="width: 0px">
              {{ save_messages ? "Ativo" : "Inativo" }}
            </p>
          </b-form-checkbox>
        </div>
      </div>

      <div>
          <h4 class="titulo">
            Apresentar formulário de origem
          </h4>
          <b-form-checkbox
            v-model="show_origin_form"
            name="is_main"
            size="lg"
            switch
          >
            <p class="info-checkbox" style="width: 0px">
              {{ show_origin_form ? "Ativo" : "Inativo" }}
            </p>
          </b-form-checkbox>
      </div>

      <b-row class="mt-2">
        <b-col cols="12" class="d-flex justify-content-end mt-3">
          <BaseButton variant="primary" @click="updateAllSettings"
            >Salvar alterações</BaseButton
          >
        </b-col>
      </b-row>
    </b-container>
    <div class="mt-1 container-user wrapper d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-tab>
</template>

<script>
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
export default {
  data() {
    return {
      acesso: 5,
      cadastro: 5,
      abriu_email: 5,
      clicou_email: 5,
      status: true,
      loading: false,
      error: false,
      save_messages: true,
      show_origin_form: false
    };
  },
  methods: {
    setQuery(tab) {
      this.$router.replace({ query: { tab: tab } });
    },
    updateAllSettings() {
      if (this.error) {
        this.$bvToast.toast("Erro ao integrar", {
          title: "Configuração de Lead",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        this.createdLeadMessage();
        this.createdLeadAcess();
        this.createdLeadRegister();
        this.createdLeadOpenEmail();
        this.createdLeadclickEmail();
        this.createdLeadRegisterForm();
        this.createdLeadFormOrigin();
        this.$bvToast.toast("Alterações salvas com sucesso", {
          title: "Configuração de Lead",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
    },
    createdLeadMessage() {
      var lead_acess = {
        meta_key: "save_messages_zap",
        meta_value: `${this.save_messages}`,
      };
      serviceSettings
        .create(lead_acess)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdLeadAcess() {
      var lead_acess = {
        meta_key: "lead_acess",
        meta_value: `${this.acesso}`,
      };
      serviceSettings
        .create(lead_acess)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdLeadRegister() {
      var lead_register = {
        meta_key: "lead_register",
        meta_value: `${this.cadastro}`,
      };
      serviceSettings
        .create(lead_register)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdLeadOpenEmail() {
      var lead_open_email = {
        meta_key: "lead_open_email",
        meta_value: `${this.abriu_email}`,
      };
      serviceSettings
        .create(lead_open_email)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdLeadclickEmail() {
      var lead_click_email = {
        meta_key: "lead_click_email",
        meta_value: `${this.clicou_email}`,
      };
      serviceSettings
        .create(lead_click_email)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdLeadRegisterForm() {
      var lead_status_form = {
        meta_key: "lead_status_form",
        meta_value: this.status + "",
      };
      serviceSettings
        .create(lead_status_form)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdLeadFormOrigin(){
      var lead_status_form = {
        meta_key: "show_origin_form",
        meta_value: this.show_origin_form + "",
      };
      serviceSettings
        .create(lead_status_form)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    getLeadFetch() {
      this.loading = true;
      var data = {
        id: "lead",
      };
      serviceSettings
        .read(data)
        .then((resp) => {
          if (resp) {
            for (let i = 0; i < resp.length; i++) {
              const lead = resp[i];
              switch(lead.meta_key){
                case 'lead_status_form':
                  this.status = lead.meta_value;
                  break;
                case 'lead_click_email':
                  this.clicou_email = lead.meta_value;
                  break;
                case 'lead_open_email':
                  this.abriu_email = lead.meta_value;
                  break;
                case 'lead_register':
                  this.cadastro = lead.meta_value;
                  break;
                case 'lead_acess':
                  this.acesso = lead.meta_value;
                  break;
                case 'save_messages_zap':
                  this.save_messages =
                  lead.meta_value == true || lead.meta_value == "true"
                    ? true
                    : false;
                  break;
                case 'show_origin_form':
                  this.show_origin_form = lead.meta_value == 'true' ? true : false
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted(){
    this.getLeadFetch();
  }
};
</script>

<style scoped>
.temperatura {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 50px;
  margin-top: 15px;
  margin-bottom: 15px;
}
input {
  width: 100%;
}
.container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.checkbox_options {
  display: flex;
  width: 100%;
}
.acao {
  width: 53.2vh;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: var(--greenn);
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}

.info-checkbox {
  margin: 0;
  padding-top: 5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}
</style>
