<template>
  <BaseModal
    name="ModalCriarProduto"
    id="ModalCriarProduto"
    idModal="ModalCriarProduto"
    size="sz"
    title="Criar produto"
    @shown="clear"
  >
    <div class="container-flex">
      <b-form-group label="Nome">
        <b-form-input
          type="text"
          name="nome"
          class="mb-0"
          v-model="nome"
          placeholder="Digite o nome do produto"
          v-validate="{ required: true, min: 3, max: 40 }"
        />
        <b-form-invalid-feedback id="errorMsg" :state="!errors.has('nome')">
          {{
            nome.length === 0
              ? "Por favor, insira o nome do produto"
              : nome.length < 3 || nome.length > 40
              ? "Mínimo de 3 caracteres e máximo de 40 caracteres"
              : ""
          }}
        </b-form-invalid-feedback>
        <span id="errorMsg">{{
          validSpaceName ? "Espaços inválidos." : ""
        }}</span>
      </b-form-group>
      <!-- <div class="mb-0 mt-3">
        <b-form-group label="Apelido">
          <b-form-input
            type="text"
            class="mb-0"
            name="apelido"
            v-model="apelido"
            placeholder="Digite o apelido do produto"
            v-validate="{ required: true, min: 3, max: 40 }"
          />
          <b-form-invalid-feedback
            id="errorMsg"
            :state="!errors.has('apelido')"
          >
            {{
              apelido.length === 0
                ? "Por favor, insira o apelido do produto"
                : apelido.length < 3 || apelido.length > 40
                ? "Mínimo de 3 caracteres e máximo de 40 caracteres"
                : ""
            }}
          </b-form-invalid-feedback>
          <span id="errorMsg">{{
            validSpaceApelido ? "Espaços inválidos." : ""
          }}</span>
        </b-form-group>
      </div> -->
      <div class="mb-0 mt-3">
        <h4 class="titulo">Valor do produto</h4>
        <money
          id="valor"
          v-model="valor"
          v-bind="money"
          class="w-100 mb-0"
        ></money>
      </div>
      <div class="mb-0 mt-3">
        <h4 class="titulo">Capa</h4>
        <div class="select-image mb-3 mt-2">
          <div @click="openModal('Upload-Editor', 'template-img')">
            <img
              :src="
                product_photo
                  ? product_photo
                  : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
              "
              alt="Imagem preview"
            />
          </div>
          <div>
            <input type="text" v-model="product_photo" placeholder="..." />
            <span @click="openModal('Upload-Editor', 'template-img')">{{
              product_photo ? "Alterar imagem" : "Selecionar imagem"
            }}</span>
          </div>
        </div>
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center mt-3" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <Upload @setImg="setImg" />
  </BaseModal>
</template>

<script>
import ConfigProdutosService from "@/services/resources/ConfigProdutosService";
const serviceProduto = ConfigProdutosService.build();
import { Money } from "v-money";
import Upload from "../Upload.vue";
import Multiselect from "vue-multiselect";

export default {
  data() {
    return {
      nome: "",
      apelido: "",
      valor: 0,
      loading: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: " ",
        precision: 2,
        masked: false,
      },
      product_photo: "",
    };
  },
  components: {
    Money,
    Multiselect,
    Upload,
  },
  computed: {
    validSpaceName() {
      const regex = /  /;
      return regex.test(this.nome);
    },
    validSpaceApelido() {
      const regex = /  /;
      return regex.test(this.apelido);
    },
  },
  methods: {
    clear() {
      this.nome = "";
      this.apelido = "";
      this.valor = 0;
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    setImg(img) {
      // console.log("img", img);
      this.product_photo = img;
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.loading = true;
          if (this.nome.length === 0) {
            this.$bvToast.toast("Obrigatório preencher o nome do produto", {
              title: "Criar produto",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }
          const regex = /  /;
          if (regex.test(this.nome)) {
            this.loading = false;
            return;
          }

          if (this.valor === 0 || this.valor === null || this.valor === "0") {
            this.$bvToast.toast("Obrigatório preencher o valor do produto", {
              title: "Criar produto",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }
          var data = {
            name: this.nome,
            surname: this.nome,
            status: 'active',
            product_value: this.valor,
            product_photo: this.product_photo,
          };
          serviceProduto
            .create(data)
            .then((resp) => {
              this.$bvToast.toast("Produto cadastrado com sucesso", {
                title: "Criar produto",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.product_photo = "";
              this.nome = "";
              this.nome = "";
              this.valor = "";
              this.$emit("update");
              this.$bvModal.hide("ModalCriarProduto");
            })
            .catch((err) => {
              // console.log(err);
              this.$bvToast.toast("Erro ao cadastrar produto", {
                title: "Criar produto",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  watch: {
    valor(e) {
      if (e < 0) {
        this.$bvToast.toast("O valor do produto precisa ser positivo", {
          title: "Criar produto",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.valor = 0;
        return;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select-image {
  display: flex;
  padding: 10px;
  border: 1px solid #ececec;
  border-radius: 10px;
  width: 100%;
  transition: all 0.3s;
  &:hover {
    border-color: var(--greenn) !important;
  }
  img {
    height: 60px;
    width: 60px;
    object-fit: cover;
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
  }
  span {
    font-size: 10px;
    text-transform: uppercase;
    text-align: right;
    padding: 5px;
    padding-bottom: 0px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      color: var(--greenn);
    }
  }
  input {
    font-size: 12px !important;
    height: 30px !important;
    padding: 10px !important;
    margin-top: 5px;
    margin-bottom: 0px !important;
  }
}
input {
  width: 100%;
}
.copy {
  cursor: pointer;
}
.flex {
  display: flex;
  gap: 15px;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.container-flex {
  display: flex;
  flex-direction: column;
}
#errorMsg {
  font-size: 12px;
  font-weight: 500;
  color: #dc3545 !important;
}
</style>
