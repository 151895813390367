<template>
    <b-tab :title="`Atividades`"
    :active="$route.query.tab === 'activities'"
    @click.once="fetch()"
    @click="setQuery('activities')">
        <b-container fluid class="mt-1 wrapper container-user" style="padding: 50px; position: relative;" id="config-activities">
            <div v-if="!$store.getters.recursos.ntask" class="__blocked">
                <svg fill="#4ea934" height="150px" width="150px" version="1.1" viewBox="0 0 330 330" ><g stroke-width="0"></g><g stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="XMLID_509_"> <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85 S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15 s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25 C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"></path> </g> </g></svg>
                <span style="color: inherit; " class="title">Acesso bloqueado</span>
                <span style="font-size: 14px; color: inherit; opacity: 0.7;">Esse recurso ainda não está incluso em seu plano,<br> fale com nosso time comercial para mais informações.</span>
                <button @click="upgradePlan()">Fazer upgrade</button>
            </div>
            <div class="d-flex justify-content-center container-user" v-if="loading">
            <b-spinner label="Loading..."></b-spinner>
            </div>
            <div  @mouseenter.once="$store.getters.recursos.ntask ? '' : disappear = true" class="container-user" v-else-if="!disappear || $store.getters.recursos.ntask" @click="numberMoreSelected = -1">
            <div class="grid-activities">
                <div class="categories" @click="isEditting = false">
                <div
                    class="categories-list"
                    v-for="(item, index) in all_category"
                    :key="index"
                    @click="isCreating = false"
                >
                    <div
                    class="categorie"
                    @click="selectedCategory(item.id)"
                    :style="
                        item.selected_category_item && !isCreating
                        ? 'border: var(--greenn) 2px solid;'
                        : ''
                    "
                    >
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="16" cy="16" r="16" fill="#4ea934" />
                        <path
                        d="M23.2 17.6H17.6V23.2H23.2V17.6Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                        <path
                        d="M14.4 17.6H8.80005V23.2H14.4V17.6Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                        <path
                        d="M23.2 8.80005H17.6V14.4H23.2V8.80005Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                        <path
                        d="M14.4 8.80005H8.80005V14.4H14.4V8.80005Z"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                    </svg>
                    <p
                        :style="
                        item.selected_category_item ? 'color:var(--greenn);' : ''
                        "
                    >
                        {{ item.title }}
                    </p>
                    </div>
                </div>
                <div v-if="!all_category.length" style="font-size: 14px; font-weight: 600; background-color: var(--greenn2); border-radius: 10px; padding: 15px 20px; text-align: center; color: var(--greenn)" class="mb-2">
                    Nenhuma atividade cadastrada
                </div>
                <div v-if="all_category.length < 8" class="categories-list" @click="createContent = JSON.parse(JSON.stringify(newCategory)), isCreating = true">
                    <div class="categorie" style="background-color: var(--greenn2)" :style="isCreating ? 'border: var(--greenn) 2px solid;' : ''">
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="16" cy="16" r="16" fill="#4ea934" />
                        <path
                        d="M16 8 V24 M8 16 H24"
                        stroke="#fff"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                    </svg>
                    <p style="color: var(--greenn)">Criar categoria</p>
                    </div>
                </div>
                </div>
                <div style="overflow: hidden;">
                <div class="header-activities" v-if="dataCategory && !isCreating">
                    <div>
                    <h1>{{ dataCategory.title }} <span v-if="editContent.id && dataCategory.activities.find((activity) => activity.id == editContent.id)" :class="{'active-edit' : isEditting}" class="edit-title">{{ editContent.id ? ` - ${dataCategory.activities.find((activity) => activity.id == editContent.id).title}` : '' }}</span></h1>
                    <p class="type">
                        {{ dataCategory.activities.length }} {{ dataCategory.activities.length == 1 ? 'atividade' : 'atividades'}}
                    </p>
                    </div>
                    <div style="display: flex; gap: 10px;">
                    <img src="@/assets/icons/edit.svg" style="width: 20px; cursor: pointer;" @click="isEditting = true, editContent = JSON.parse(JSON.stringify(dataCategory)), numberMoreSelected = -1"/>
                    <img src="@/assets/img/icons/trash2.svg" @click="deleteCategory(dataCategory.id)" style="width: 20px; cursor: pointer;"/>
                    </div>
                </div>
                <div class="header-activities" v-else>
                    <div>
                    <h1>Criar categoria <span :class="{'active-edit' : isEditting}" class="edit-title">- Primeira atividade</span></h1>
                    <p class="type">
                        Criar uma nova categoria para suas atividades
                    </p>
                    </div>
                </div>
                <div class="category-content" :class="{'category-content-edit' : isEditting}">
                    <div class="grid-card">
                    <div class="grid-activities-itens" v-if="dataCategory && !isCreating">
                        <div v-for="(item, index) in dataCategory.activities" :key="index" style="display: grid; grid-template-columns: 1fr; gap: 10px; height: fit-content;" @click.stop="">
                        <div class="activitie-block">
                            <div class="header">
                            <div style="display: flex; align-items: center; gap: 10px;">
                                <img
                                v-if="item.type == 'email'"
                                src="@/assets/icons/email.svg"
                                alt="email"
                                />
                                <img
                                v-if="item.type == 'call'"
                                src="@/assets/icons/phone.svg"
                                alt="email"
                                />
                                <img
                                v-if="item.type == 'mensage'"
                                src="@/assets/icons/message-square.svg"
                                alt="email"
                                />
                                <p>{{ index + 1 }}- {{ item.title }}</p>
                            </div>
                            <div style="display: flex; align-items: center; gap: 10px;">
                                <img src="@/assets/icons/eye_green.svg" alt="eye_green"  style="height: 25px; cursor: pointer;" @click="numberMoreSelected == index ? numberMoreSelected = -1 : numberMoreSelected = index">
                                <img src="@/assets/icons/edit.svg" style="height: 20px; cursor: pointer;" @click="isEditting = !isEditting, numberMoreSelected = -1, editContent = JSON.parse(JSON.stringify(item)), item.type == 'email' ? initEmail(item) : ''"/>
                                <img src="@/assets/img/icons/trash2.svg" @click="dataCategory.activities.length > 1 ? deleteActivity(item.id) : '', dataCategory.activities.length > 1 ? numberMoreSelected = -1 : ''" style="transition: all 0.3s; height: 20px;" :style="dataCategory.activities.length == 1 ? 'filter: saturate(0);' : ' cursor: pointer;'"/>
                                <!-- <b-form-checkbox
                                name="is_main"
                                size="lg"
                                v-model="item.status"
                                switch
                                style="margin-top: -4px"
                                >
                                </b-form-checkbox> -->
                            </div>
                            </div>
                            <b-collapse v-model="moreSelected[index]" class="see-more">
                            <div v-if="item.type == 'email'"><div style="zoom: 0.6;" v-html="item.body"></div></div>
                            <div v-else> <p>{{item.body}}</p></div>
                            </b-collapse>
                        </div>
                        <!-- <div class="image-control">
                            <img
                            :class="{ 'disabled-arrow' : index == 0}"
                            class="first-arrow"
                            src="@/assets/editor/chevron-down.svg"
                            @click="index == 0 ? '' : 'moveUp(index)'"
                            title="Mover para cima"
                            style="margin-top: 0px;"
                            />
                            <img
                            :class="{ 'disabled-arrow' : index + 1 == dataCategory.activities.length}"
                            class="last-arrow"
                            src="@/assets/editor/chevron-down.svg"
                            @click="index + 1 == dataCategory.activities.length ? '' : 'moveDown(index)'"
                            title="Mover para baixo"
                            style=""
                            />
                        </div> -->
                        </div>
                        <div v-if="dataCategory.activities.length < 10" class="activitie-block" style="cursor: pointer;" @click="isEditting = true, editContent = JSON.parse(JSON.stringify(addActivity)), initEmail()">
                        <div class="header">
                            <div style="display: flex; align-items: center; gap: 10px;">
                            <svg
                            width="25"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="16" cy="16" r="14" stroke="#4ea934" stroke-width="2"/>
                                <path
                                d="M16 8 V24 M8 16 H24"
                                stroke="#4ea934"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                />
                            </svg>
                            <p>
                                Adicionar atividade
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div v-else>
                        <label>Título</label>
                        <input
                        style="width: 100%;"
                        id="title"
                        type="text"
                        v-model="createContent.title"
                        />
                        <label for="title" class="mt-2">Descrição</label>
                        <b-form-textarea
                        placeholder=""
                        id="textarea"
                        v-model="createContent.description"
                        no-resize
                        style="height: 200px !important; margin-bottom: 15px !important;"
                        ></b-form-textarea>
                        <div style="display: flex; gap: 10px; justify-content: right; margin: 15px 5px 0 0;">
                        <BaseButton @click="isCreating = !isCreating" v-if="all_category.length" variant="link-info">Voltar</BaseButton>
                        <BaseButton :disabled="!createContent.title || !createContent.description" @click="isEditting = true, editContent = JSON.parse(JSON.stringify(addActivity)), isCreating = true" variant="primary">Próximo</BaseButton>
                        </div>
                    </div>
                    </div>
                    <div v-if="isEditting || delayedIsEditting" style="max-width: 100%; overflow: auto; max-height: 100%; border: 1px solid var(--white-medium); border-radius: 10px; padding: 25px 30px; margin-top: 10px;">
                    <div v-if="!editContent.id">
                        <div style="display: grid; grid-template-columns: 170px 1fr; gap: 20px; padding-bottom: 25px; border-bottom: 1px solid var(--white-medium); margin-bottom: 25px;">
                        <div>
                            <label>Tipo</label>
                            <div class="select-type">
                            <div @click="editContent.type = 'mensage'" :style="editContent.type == 'mensage' ? 'filter: saturate(1); background-color: var(--greenn2);' : ''">
                                <img
                                src="@/assets/icons/message-square.svg"
                                alt="email"
                                />
                            </div>
                            <div @click="editContent.type = 'email'" :style="editContent.type == 'email' ? 'filter: saturate(1); background-color: var(--greenn2);' : ''">
                                <img
                                src="@/assets/icons/email.svg"
                                alt="email"
                                />
                            </div>
                            <div @click="editContent.type = 'call'" :style="editContent.type == 'call' ? 'filter: saturate(1); background-color: var(--greenn2);' : ''">
                                <img
                                src="@/assets/icons/phone.svg"
                                alt="email"
                                />
                            </div>
                            </div>
                        </div>
                        <div>
                            <label>Título</label>
                            <input type="text" class="w-100" :placeholder="editContent.type == 'email' ? 'E-mail de contrato' : editContent.type == 'call' ? 'Ligação de apresentação' : 'Mensagem para marcar reunião'" style="margin-bottom: 0 !important;" v-model="editContent.title">
                        </div>
                        </div>
                        <div v-show="editContent.type == 'email'">
                        <div id="bar">
                            <EmailEditor
                            ref="emailEditor"
                            v-on:load="editorLoaded"
                            v-on:designUpdated="designUpdated"
                            :designTags="designTags"
                            style="height: 500px; max-width: 100%; border-radius: 10px; overflow: hidden; border: 1px solid var(--white-medium);"
                            :options="options_unlayer"
                            locale="pt-BR"
                            :translations="traducaoUnlayer"
                            :projectId="168946"
                            :templateId="369449"
                            apiKey="xTBzcM8XoHvPMhcWa1H83iT6j3qqTuddUfsLGFhiLO5SI9ET2XSqbHIqgmHUh1cI"
                            />
                        </div>
                        </div>
                        <div v-show="editContent.type != 'email'">
                        <label>{{ editContent.type == 'call' ? 'Conteúdo que deve ser abordado na ligação' : 'Conteúdo da mensagem a ser enviada' }}</label>
                        <b-form-textarea
                            :placeholder="editContent.type == 'call' ? 'Nessa ligação deve ser explicado ao lead sobre...' : `Olá, {{nome}}! Tudo bem?`"
                            id="textarea"
                            v-model="editContent.body"
                            no-resize
                            style="height: 200px !important"
                        ></b-form-textarea>
                        </div>
                    </div>
                    <div v-else-if="editContent.activities">
                        <div style="display: grid; grid-template-columns: 1fr 60px; gap: 15px;">
                        <div>
                            <label for="title">Título da categoria</label>
                            <input
                            style="width: 100%;"
                            id="title"
                            type="text"
                            v-model="editContent.title"
                            />
                        </div>
                        <div>
                            <label for="title">Status</label>
                            <b-form-checkbox
                            name="is_main"
                            size="lg"
                            v-model="editContent.status"
                            switch
                            style="margin-top: 15px"
                            >
                            </b-form-checkbox>
                        </div>
                        </div>
                        
                        <label for="title" class="mt-2">Descrição</label>
                        <b-form-textarea
                        :placeholder="`Olá, {{nome}}! Tudo bem?`"
                        id="textarea"
                        v-model="editContent.description"
                        no-resize
                        style="height: 200px !important"
                        ></b-form-textarea>
                    </div>
                    <div v-else-if="editContent && editContent.type == 'call'">
                        <label for="title">Título</label>
                        <input
                        style="width: 100%"
                        id="title"
                        type="text"
                        v-model="editContent.title"
                        />
                        <label for="title" class="mt-2">Conteúdo que deve ser abordado na ligação</label>
                        <b-form-textarea
                        :placeholder="`Olá, {{nome}}! Tudo bem?`"
                        id="textarea"
                        v-model="editContent.body"
                        no-resize
                        style="height: 200px !important"
                        ></b-form-textarea>
                    </div>
                    <div v-else-if="editContent && editContent.type == 'email'">
                        <label for="title">Título</label>
                        <input
                        style="width: 100%"
                        id="title"
                        type="text"
                        v-model="editContent.title"
                        />
                        <div id="bar">
                        <EmailEditor
                            ref="emailEditor"
                            v-on:load="editorLoaded"
                            v-on:designUpdated="designUpdated"
                            :designTags="designTags"
                            style="height: 600px; max-width: 100%; border-radius: 10px; overflow: hidden; border: 1px solid var(--white-medium);"
                            :options="options_unlayer"
                            locale="pt-BR"
                            :translations="traducaoUnlayer"
                            :projectId="168946"
                            :templateId="369449"
                            apiKey="xTBzcM8XoHvPMhcWa1H83iT6j3qqTuddUfsLGFhiLO5SI9ET2XSqbHIqgmHUh1cI"
                        />
                        </div>
                    </div>
                    <div v-else-if="editContent && editContent.type == 'mensage'">
                        <label for="title">Título</label>
                        <input
                        style="width: 100%"
                        id="title"
                        type="text"
                        v-model="editContent.title"
                        />
                        <label for="title" class="mt-2">Mensagem</label>
                        <b-form-textarea
                        :placeholder="`Olá, {{nome}}! Tudo bem?`"
                        id="textarea"
                        v-model="editContent.body"
                        no-resize
                        style="height: 200px !important"
                        ></b-form-textarea>
                    </div>
                    <div style="display: flex; gap: 10px; justify-content: right; margin: 15px 5px 0 0;">
                        <BaseButton @click="isEditting = !isEditting" variant="link-info">Voltar</BaseButton>
                        <BaseButton @click="isCreating ? addCategory() : (editContent.id && !editContent.activities) ? editActivity() : editContent.activities ? editCategory() : createActivity(dataCategory.id)" variant="primary">Salvar</BaseButton>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </b-container>
    </b-tab>
</template>

<script>
import { EmailEditor } from "../../../node_modules/vue-email-editor";
import traducao_unlayer from "../../services/libs/traducao_unlayer.json";
import sample from "../../assets/unlayer/sample.json";
import AtividadesService from "@/services/resources/AtividadesService";
const serviceSettings = AtividadesService.build();
import ActivitieService from "@/services/resources/ActivitieService";
const serviceActivitie = ActivitieService.build();
import Multiselect from "vue-multiselect";
import Vue from "vue";
export default {
components: {
    EmailEditor,
    Multiselect,
},
data() {
    return {
    disappear: false,
    moreSelected: [],
    numberMoreSelected: '',
    selected_activity: null,
    activeCollapse: null,
    selected_category: null,
    all_category: [],
    loading: false,
    dataCategory: null,
    isEditting: false,
    isCreating: false,
    editContent: '',
    createContent: '',
    delayedIsEditting: false,
    addActivity: {
        type: 'mensage',
        title: '',
        body: '',
        json: '',
    },
    newCategory: {
        title: '',
        description: '',
        activities: [],
    },
    // email
    html: "",
    raw: sample,
    raw2: '',
    traducaoUnlayer: traducao_unlayer,
    options_unlayer: {
        mergeTags: [
        { name: "Nome do cliente", value: "{{nome}}" },
        { name: "Primeiro nome do cliente", value: "{{primeiro_nome}}" },
        { name: "E-mail do cliente", value: "{{lead}}" },
        ],
        features: {
        textEditor: {
            cleanPaste: false,
        },
        },
    },
    designTags: {
        business_name: this.nameSystem,
        current_user_name: this.nameSystem,
        link_botao: "https://google.com.br",
    },
    step: 1,
    titulo: "",
    remetente: null,
    remetentes: [],
    btn_selected: "blank",
    legados: [],
    legados_page: 1,
    atuais_page: 1,
    legados_last_page: 1,
    atuais_last_page: 1,
    atuais: [],
    loading: false,
    html: "",
    valid_remetente: "",
    htmlmail: ``,
    template_selected: ``,
    };
},
computed:{
    nameSystem() {
      return this.$store.getters["getNameSystem"];
    },
  },
watch: {
    isEditting() {
    setTimeout(() => {
        this.delayedIsEditting = this.isEditting
    }, 500);
    },
    isCreating() {
    if (this.isCreating) {
        this.isEditting = false
    }
    },
    dataCategory() {
        var result = this.dataCategory.activities;
        if (result) {
        for (let i = 0; i < result.length; i++) {
            if (result[i].status === "active") {
                result[i].status = true;
            } else if (result[i].status === true) {
                return;
            } else {
                result[i].status = false;
            }
        }
    this.dataCategory.activities = result;
    }
    },
    numberMoreSelected() {
    if (this.dataCategory) {
        let array = []
        for (let i = 0; i < this.dataCategory.activities.length; i++) {
        array.push(false)
        }
        if (this.numberMoreSelected >= 0) {
        array[this.numberMoreSelected] = true
        }
        this.moreSelected = array
    }
    }
},
methods: {
    upgradePlan() {
        $crisp.push(["set", "session:segments", [["financeiro"]]]);
        $crisp.push(["do", "chat:open"]);
        $crisp.push([
            "do",
            "message:send",
            ["text", `Quero fazer um upgrade no meu plano e ter acesso ao CRM.`],
        ]);
    },
    setQuery(tab) {
        this.$router.replace({ query: { tab: tab } });
    },
    editActivity(){
    if (this.editContent.type != 'email' && this.editContent.body === "") {
        this.$bvToast.toast(this.editContent.type == 'call' ? "Obrigatório preencher a descrição da call" : "Obrigatório preencher o conteúdo da mensagem", {
        title: "Gestão de atividades",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
        });
        return;
    }
    if (this.editContent.title === "") {
        this.$bvToast.toast("Obrigatório preencher o título", {
        title: "Gestão de atividades",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
        });
        return;
    }
    var data = {
        id: this.editContent.id,
        title: this.editContent.title,
        body: this.editContent.body,
    };
    if (this.editContent.type == 'email') {
        data.json = JSON.stringify(this.raw2)
        data.body = this.html
    }
    serviceActivitie
        .update(data)
        .then((resp) => {
        let activity = this.dataCategory.activities.find((activity) => activity.id == this.editContent.id)
        let index = this.dataCategory.activities.findIndex((activity) => activity.id == this.editContent.id)
        activity.title = resp.categoria.title
        activity.body = resp.categoria.body
        if (activity.type == 'email') {
            activity.json = JSON.parse(resp.categoria.json)
        }
        this.dataCategory.activities.splice(index, 1, activity);
        this.isEditting = !this.isEditting
        })
        .catch((error) => {
        this.$bvToast.toast("Erro ao atualizar atividade", {
            title: "Enviar mensagem WhatsApp",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
        });
        })
        .finally((error) => {
        });
    },
    createActivity(category_id) {
        var data = {
            title: this.editContent.title,
            category_id: category_id,
            type: this.editContent.type
        };
        const exportHtmlPromise = new Promise((resolve) => {
            this.$refs.emailEditor.editor.exportHtml((data2) => {
                if(data.type == 'email'){
                    data.body = data2.html;
                    data.json = JSON.stringify(data2.design);
                }else{
                    data.body = this.editContent.body;
                }
                resolve();
            });
        });

        exportHtmlPromise.then(() => {
            if (data.title === "" || (data.type == 'email' && data.body == '')) {
                this.$bvToast.toast("Preencha todos os campos", {
                    title: "Criar atividade",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                });
                return;
            }
            serviceActivitie
                .create(data)
                .then((resp) => {
                    this.all_category.find((category) => category.selected_category_item == true).activities.push(resp);
                    this.isEditting = false;
                })
                .catch((err) => {
                    this.$bvToast.toast("Erro ao cadastrar atividade", {
                        title: "Criar atividade",
                        variant: "danger",
                        autoHideDelay: 5000,
                        appendToast: true,
                    });
                })
                .finally(() => {
            });
        });
    },
    initEmail(item){
    if (item) {
        this.html = item.body;
        this.raw2 = JSON.parse(item.json);
    }
    },
    editorLoaded() {
    if (!this.editContent.id) {
        this.$refs.emailEditor.editor.loadDesign(this.raw);
    } else {
        this.$refs.emailEditor.editor.loadDesign(this.raw2);
    }
    },
    designUpdated() {
    this.$refs.emailEditor.editor.exportHtml((data) => {
        this.html = data.html;
        this.raw2 = data.design;
    });
    },
    inactiveActivity(id) {
    this.loading = true;
    var data = {
        id: id,
        status: "inactive",
    };
    serviceActivitie
        .update(data)
        .then((resp) => {
        let index = this.dataCategory.findIndex((activity)=> activity.id == id)
        this.all_category.find((category) => category.selected_category_item == true).activities[index].status = "inactive"
        })
        .catch((error) => {
        this.$bvToast.toast("Erro ao inativar atividade", {
            title: "Atividade",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
        });
        })
        .finally(() => {
        this.loading = false;
        });
    },
    activeActivity(id) {
    this.loading = true;
    var data = {
        id: id,
        status: "active",
    };
    serviceActivitie
        .update(data)
        .then((resp) => {
        let index = this.dataCategory.findIndex((activity)=> activity.id == id)
        this.all_category.find((category) => category.selected_category_item == true).activities[index].status = "active"
        })
        .catch((error) => {
        this.$bvToast.toast("Erro ao ativar atividade", {
            title: "Atividade",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
        });
        })
        .finally(() => {
        this.loading = false;
        });
    },
    deleteActivity(id) {
    Vue.swal({
        title: "Atividade",
        text: `Deseja deletar a atividade selecionada? Essa ação não poderá ser revertida.`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
    }).then((result) => {
        if (result.isConfirmed) {
        var data = {
            id: id,
        };
        serviceActivitie
            .destroy(data)
            .then((resp) => {
            let index = this.dataCategory.activities.findIndex((activity) => activity.id == id)
            this.all_category.find((category) => category.selected_category_item == true).activities.splice(index, 1)
            })
            .catch((error) => {
            this.$bvToast.toast("Erro ao inativar atividade", {
                title: "Atividade",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
            });
            })
            .finally(() => {
            });
        }
    });
    },
    selectedCategory(id) {
    var result = this.all_category;
    for (let i = 0; i < result.length; i++) {
        const element = result[i];
        if (result[i].id == id) {
        result[i].selected_category_item = true;
        this.dataCategory = result[i];
        } else {
        result[i].selected_category_item = false;
        }
    }
    this.all_category = result;
    },
    deleteCategory(id) {
    Vue.swal({
        title: "Atividade",
        text: `Deseja deletar a categoria selecionada? Essa ação não poderá ser revertida.`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
    }).then((result) => {
        if (result.isConfirmed) {
        var data = {
            id: id,
        };
        serviceSettings
            .destroy(data)
            .then(() => {
            let index = this.all_category.findIndex((category) => category.id == id)
            this.all_category.splice(index, 1)
            if (this.all_category.length) {
                var result = this.all_category;
                for (let i = 0; i < result.length; i++) {
                if (i == 0) {
                    result[i].selected_category_item = true;
                    this.dataCategory = result[i];
                } else {
                    result[i].selected_category_item = false;
                }
                }
                this.all_category = result;
            } else {
                this.dataCategory = ''
                this.createContent = JSON.parse(JSON.stringify(this.newCategory))
                this.isCreating = true
            }
            })
            .catch((error) => {
            this.$bvToast.toast("Erro ao deletar categoria", {
                title: "Categoria",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
            });
            })
            .finally(() => {
            });
        }
    });
    },
    fetch() {
    this.loading = true;
    var data = {
        per_page: 9999,
    };
        serviceSettings
        .search(data)
        .then(async (resp) => {
        var result = resp;
            
            for (let i = 0; i < result.length; i++) {
                if (i == 0) {
                    result[i].selected_category_item = true;
                    result[i].activities = await serviceSettings.createId({id:`/activities`,category_id:result[i].id});
                    this.dataCategory = result[i];
                } else {
                    result[i].selected_category_item = false;
                    result[i].activities = await serviceSettings.createId({id:`/activities`,category_id:result[i].id});
                }
                if (result[i].status === "active") {
                    result[i].status = true;
            } else {
                result[i].status = false;
            }
        }
        this.all_category = result;
        if (!result.length){
            this.isCreating = true
            this.createContent = JSON.parse(JSON.stringify(this.newCategory))
        }
    })
        .finally(() => {
            this.loading = false;
        });
    },
    editCategory(){
    var data = {
        id: this.editContent.id,
        description: this.editContent.description,
        title: this.editContent.title,
        status: this.editContent.status ? 'active' : 'inactive',
    };
    serviceSettings
        .update(data)
        .then((resp) => {
        this.all_category.find((category) => category.id == this.editContent.id).description = this.editContent.description
        this.all_category.find((category) => category.id == this.editContent.id).title = this.editContent.title
        this.all_category.find((category) => category.id == this.editContent.id).status = this.editContent.status
        this.isEditting = false
        })
        .catch((err) => {
        })
        .finally(() => {
        });
    },
    addCategory(){
    if (this.editContent.title === "" || this.editContent.body == '') {
        this.$bvToast.toast("Preencha todos os campos da atividade", {
        title: "Criar categoria",
        variant: "danger",
        autoHideDelay: 5000,
        appendToast: true,
        });
        return;
    }
    var data = {
        description: this.createContent.description,
        title: this.createContent.title,
    };
    serviceSettings
        .create(data)
        .then((resp) => {
        let newCategory = resp;
        newCategory.activities = []
        this.all_category.push(newCategory)
        this.selectedCategory(resp.id)
        this.createActivity(resp.id)
        this.isCreating = false
        })
        .catch((err) => {
        })
        .finally(() => {
        });
    },
},
};
</script>

<style scoped lang="scss">

.image-control {
height: auto;
width: 35px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
.first-arrow {
    margin-top: 6px;
    rotate: 180deg;
}
.last-arrow {
}
img {
    cursor: pointer;
}
.disabled-arrow {
    filter: invert(50%);
    cursor: default !important;
}
}
.category-content{
width: 200%;
display: grid;
grid-template-columns: 1fr 1fr;
transition: all 0.5s;
height: calc(100% - 150px);
}
.category-content-edit{
transform: translate(-50%);
}
.edit-title {
transition: opacity 0.5s;
opacity: 0;
}
.active-edit{
opacity: 1 !important;
}
.see-more {
>div {
    margin: 15px 85px 0 32px;
    max-height: 310px;
    overflow: auto;
}
}
.grid-activities {
display: grid;
width: 100%;
grid-template-columns: 2fr 5fr;
gap: 25px;
overflow: hidden;
height: 75vh;
.grid-card {
    border-radius: 10px; 
    padding: 25px 30px;
    overflow: auto; 
    border: 1px solid var(--white-medium); 
    margin-top: 10px;
}
.grid-activities-itens {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    p {
    margin-bottom: 0;
    }

    .activitie-block {
    width: 100%;
    border: var(--white-medium) solid 1px;
    border-radius: 10px;
    padding: 20px;
    height: fit-content;
    .header {
        display: flex;
        justify-content: space-between;

        p {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 600;
        color: var(--gray01);
        margin: 0 !important;
        }
    }
    }
}

.header-activities {
    display: grid;
    margin: 0 10px;
    grid-template-columns: calc(100% - 70px) 50px;
    gap: 20px;
    width: calc(100% - 20px);
    max-width: calc(100% - 20px);
    h1 {
    font-size: 22px;
    font-weight: 600;
    color: var(--gray01);
    font-family: Montserrat;
    position: relative;
    z-index: 1;
    margin: 0;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    }
}

.categories-list {
    width: 100%;
    h1 {
    font-weight: 600;
    font-size: 14px;
    color: var(--gray01);
    }
    .categorie {
    border: 2px solid var(--white-light);
    display: grid;
    grid-template-columns: 32px 1fr;
    gap: 10px;
    background: var(--white-light);
    border-radius: 10px;
    align-items: center;
    height: 50px;
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 5px;
    transition: all 0.3s;
    p {
        color: var(--gray03);
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        cursor: pointer;
        overflow: hidden; // Removendo barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final
        display: -webkit-box;
        -webkit-line-clamp: 2; // Quantidade de linhas
        -webkit-box-orient: vertical; 
    }
    }

    select {
    padding-left: 50px !important;
    }

    .btn-make {
    width: 100%;

    button {
        width: 100%;
    }
    }
}
}
.select-type {
display: flex; 
gap: 10px;
div {
    width: 50px;
    aspect-ratio: 1;
    border: 1px solid var(--white-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    filter: saturate(0);
    transition: all 0.3s;
    cursor: pointer;
}
img {
    width: 25px;
}
}
.add {
font-size: 14px;
background: var(--gray01);
font-weight: 600;
color: #fff;
padding: 5px;
border-radius: 4px;
transition: all 0.3s;

&:hover {
    background: var(--gray01) e0;
}
}

h5 {
font-weight: 600;
font-size: 18px;
color: var(--greenn);
}

.container-user {
height: 75vh;
overflow: hidden;
}
</style>

<style>
#config-activities .unlayer-editor {
    height: unset !important;
    min-height: unset !important;
}
#config-activities .unlayer-editor iframe {
    min-height: 70vh !important;
    zoom: 0.5;
    max-height: 600px;
}
</style>