<template>
  <b-tab
    :title="`Vendedor`"
    :active="$route.query.tab === 'vendedor'"
    @click="setQuery('vendedor')"
    @click.once="fetch()"
  >
    <b-container fluid class="mt-1 wrapper container-user" v-show="!loading">
      <h5 class="my-3">Configuração de vendedor</h5>
      <div class="grid">
        <div class="acao">
          <div class="sub-usuario">
            <h4 class="titulo">Transferir lead</h4>
            <b-form-checkbox
              v-model="transferir_lead"
              name="is_main"
              size="lg"
              switch
            >
              <p class="info-checkbox" style="width: 0px">
                {{ transferir_lead ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Vendedor pode transferir leads para outro vendedor."
          />
        </div>
        <div class="acao">
          <div class="lead-sem-vendedor">
            <h4 class="titulo">Ver leads sem vendedor</h4>
            <b-form-checkbox
              v-model="lead_sem_vendedor"
              name="is_main"
              size="lg"
              switch
            >
              <p class="info-checkbox" style="width: 0px">
                {{ lead_sem_vendedor ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Vendedor pode ver leads sem vendedor."
          />
        </div>
        <div class="acao">
          <div class="pre-vendedor">
            <h4 class="titulo">Pré-vendedor</h4>
            <b-form-checkbox
              v-model="pre_vendedor"
              name="is_main"
              size="lg"
              @change="!$event ? preSellerAutomation = false : null"
              switch
            >
              <p class="info-checkbox" style="width: 0px">
                {{ pre_vendedor ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Ativa ou desativa pré-vendedor no sistema."
          />
        </div>
        <div class="acao">
          <div class="pre-vendedor">
            <h4 class="titulo">Automações por pré-vendedor</h4>
            <b-form-checkbox
              v-model="preSellerAutomation"
              name="is_main"
              size="lg"
              switch
              :disabled="!JSON.parse(pre_vendedor)"
            >
              <p class="info-checkbox" style="width: 0px">
                {{ preSellerAutomation ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Com a opção ativa, pré-vendedores podem disparar automações de mensagens"
          />
        </div>

        <div class="acao">
          <div class="mudar_tag">
            <h4 class="titulo">Criar tags</h4>
            <b-form-checkbox
              v-model="mudar_tag"
              name="is_main"
              size="lg"
              switch
            >
              <p class="info-checkbox" style="width: 0px">
                {{ mudar_tag ? "Ativo" : "Inativo" }}
              </p>
            </b-form-checkbox>
          </div>
          <img
            width="17px"
            height="17px"
            src="@/assets/img/icons/help-circle.svg"
            alt="icon"
            v-b-tooltip.hover
            title="Permite que os vendedores criem novas tags"
          />
        </div>
      </div>
      <b-row class="mt-2">
        <b-col cols="12" class="d-flex justify-content-end mt-3">
          <BaseButton variant="primary" @click="updateAllSettings"
            >Salvar alterações</BaseButton
          >
        </b-col>
      </b-row>
    </b-container>
    <div class="mt-1 container-user wrapper d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-tab>
</template>

<script>
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

export default {
  data() {
    return {
      transferir_lead: true,
      lead_sem_vendedor: true,
      pre_vendedor: true,
      mudar_tag: true,
      loading: false,
      error: false,
      preSellerAutomation: false
    };
  },
  methods: {
    setQuery(tab) {
      this.$router.replace({ query: { tab: tab } });
    },
    updateAllSettings() {
      if (this.error) {
        this.$bvToast.toast("Erro ao integrar", {
          title: "Configuração de Vendedor",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        this.created_transferir_lead();
        this.created_leads_not_seller();
        this.created_pre_seller();
        this.created_change_tag();
        this.createdPreSellerAutomation();
        this.$bvToast.toast("Alterações salvas com sucesso", {
          title: "Configuração de Vendedor",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
      }
    },
    created_transferir_lead() {
      var tranferir_lead = {
        meta_key: "seller_can_transfer_lead",
        meta_value: this.transferir_lead + "",
      };
      serviceSettings
        .create(tranferir_lead)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    created_leads_not_seller() {
      var leads_not_seller = {
        meta_key: "seller_can_see_lead_without_seller",
        meta_value: this.lead_sem_vendedor + "",
      };
      serviceSettings
        .create(leads_not_seller)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    created_pre_seller() {
      var pre_seller = {
        meta_key: "has_pre_seller",
        meta_value: this.pre_vendedor + "",
      };
      serviceSettings
        .create(pre_seller)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdPreSellerAutomation() {
      var preSellerAutomation = {
        meta_key: "preSellerAutomation",
        meta_value: this.preSellerAutomation + "",
      };
      serviceSettings
        .create(preSellerAutomation)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    created_change_tag() {
      var data = {
        meta_key: "change_tag",
        meta_value: this.mudar_tag + "",
      };
      serviceSettings.create(data).then((resp) => {});
    },
    fetch() {
      this.loading = true;
      var data = {
        id: "seller",
      };
      serviceSettings
        .read(data)
        .then((resp) => {
          if(!resp.length){
            this.updateAllSettings();
            }
          if (resp) {
            for (let i = 0; i < resp.length; i++) {
              const seller = resp[i];
              if (seller.meta_key == "seller_can_transfer_lead") {
                this.transferir_lead = seller.meta_value;
              } else if (seller.meta_key == "has_pre_seller") {
                this.pre_vendedor = seller.meta_value;
              } else if (
                seller.meta_key == "seller_can_see_lead_without_seller"
              ) {
                this.lead_sem_vendedor = seller.meta_value;
              } else if (seller.meta_key == "change_tag") {
                this.mudar_tag = seller.meta_value;
              }
              else if (seller.meta_key == "preSellerAutomation") {
                this.preSellerAutomation = seller.meta_value;
              }
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.acao {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px 50px;
}
input {
  width: 100%;
}
.container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: var(--greenn);
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}
.info-checkbox {
  margin: 0;
  padding-top: 5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}
</style>
