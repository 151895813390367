<template>
  <b-tab
    :title="`LGPD`"
    :active="$route.query.tab === 'lgpd'"
    @click="setQuery('lgpd')"
    @click.once="fetch()"
  >
    <b-container fluid class="mt-1 wrapper container-user" v-show="!loading">
      <h5 class="my-3">Configuração de LGPD</h5>
      <div class="gap">
        <!-- <div class="descricao-formulario">
          <div class="container-titulo">
            <h4 class="titulo">
              Texto de descrição no formulario LGPD (Lei geral da proteção de
              dados)
            </h4>
            <img
              width="17px"
              height="17px"
              src="@/assets/img/icons/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="texto de conformidade no formulário."
            />
          </div>
          <div class="quill-editor-lgpd">
            <quill-editor ref="myQuillEditor" v-model="formulario_lgpd" :options="editorOption" />
          </div>
        </div> -->
        <div class="descricao-formulario">
          <div class="container-titulo">
            <h4 class="titulo">
              Texto de descrição no modal LGPD (Lei geral da proteção de dados)
            </h4>
            <img
              width="17px"
              height="17px"
              src="@/assets/img/icons/help-circle.svg"
              alt="icon"
              v-b-tooltip.hover
              title="Texto de conformidade no modal."
            />
          </div>
          <div class="quill-editor-lgpd">
            <quill-editor
              ref="myQuillEditor"
              v-model="modal_lgpd"
              :options="editorOption"
            />
          </div>
        </div>
        <div class="tema-modal">
          <h4 class="titulo">Escolha um tema para o modal de "Cookies LGPD</h4>
          <b-form-group>
            <multiselect
              v-model="selected_tema"
              id="seller"
              label="text"
              track-by="value"
              placeholder="Seleciona um Tema"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="select_tema"
              :multiple="false"
              :taggable="false"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
            >
              <span slot="noOptions">Pesquise por um status</span>
              <span slot="noResult">Oops! Nenhum status encontrado.</span>
            </multiselect>
          </b-form-group>
        </div>
        <div class="opcao-botao">
          <h4 class="titulo">
            Escolha as opções de botão do modal de "Cookies LGPD"
          </h4>
          <b-form-group>
            <multiselect
              v-model="selected_botao"
              id="seller"
              label="text"
              track-by="value"
              placeholder="Seleciona o Tipo de Botão"
              selectLabel=""
              deselectLabel=""
              selectedLabel=""
              open-direction="bottom"
              :options="select_botao"
              :multiple="false"
              :taggable="false"
              :internal-search="true"
              :clear-on-select="false"
              :close-on-select="true"
              :show-no-results="true"
              :hide-selected="false"
            >
              <span slot="noOptions">Pesquise por um status</span>
              <span slot="noResult">Oops! Nenhum status encontrado.</span>
            </multiselect>
          </b-form-group>
        </div>
      </div>
      <b-row class="mt-2">
        <b-col cols="12" class="d-flex justify-content-end mt-3">
          <BaseButton variant="primary" @click="updateAllSettings"
            >Salvar alterações</BaseButton
          >
        </b-col>
      </b-row>
    </b-container>
    <div class="mt-1 container-user wrapper d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </b-tab>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Quill from "quill";
import { quillEditor } from "vue-quill-editor";

import Multiselect from "vue-multiselect";

import VueUploadMultipleImage from "vue-upload-multiple-image";

import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

var toolbarOptions = [
  ["bold", "italic", "underline", "strike", "link"],
  ["blockquote", "code-block"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ direction: "rtl" }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ align: [] }],
];

export default {
  components: {
    VueUploadMultipleImage,
    quillEditor,
    Quill,
    Multiselect,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
        },
      },
      formulario_lgpd:
        "Eu concordo em receber as comunicações e ofertas personalizadas de acordo com meus interesses",
      modal_lgpd: "utilizamos cookies por aqui.",
      selected_tema: {},
      selected_botao: {},
      select_tema: [
        { value: "default", text: "Tema Padrão" },
        { value: "dark", text: "Tema Escuro" },
      ],
      select_tema2: [
        { value: "default", text: "Tema Padrão" },
        { value: "dark", text: "Tema Escuro" },
        { value: "red", text: "Tema Vermelho" },
        { value: "blue", text: "Tema Azul" },
        { value: "purple", text: "Tema Roxo" },
      ],
      select_botao: [
        { value: "button", text: "Botão unico Continuar e Fechar" },
        { value: "double_button", text: "Botão Continuar e botão Cancelar" },
      ],
      loading: false,
      error: false,
    };
  },
  methods: {
    setQuery(tab) {
      this.$router.replace({ query: { tab: tab } });
    },
    updateAllSettings() {
      if (this.error) {
        this.$bvToast.toast("Erro ao integrar", {
          title: "Configuração LGPD",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
      } else {
        this.createdFormulario();
        this.createdModalLgpd();
        if (this.selected_tema.value) {
          this.createdThemeModal();
        }
        if (this.selected_botao.value) {
          this.created_botao_modal();
        }
        this.$bvToast.toast("Alterações Salvas com Sucesso", {
          title: "Configuração LGPD",
          variant: "info",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }
    },
    createdFormulario() {
      var form_description = {
        meta_key: "lgpd_form_description",
        meta_value: this.formulario_lgpd,
      };
      serviceSettings
        .create(form_description)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdModalLgpd() {
      var modal_description = {
        meta_key: "lgpd_modal_description",
        meta_value: this.modal_lgpd,
      };
      serviceSettings
        .create(modal_description)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    createdThemeModal() {
      var modal_theme = {
        meta_key: "lgpd_modal_theme",
        meta_value: this.selected_tema.value,
      };
      serviceSettings
        .create(modal_theme)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    created_botao_modal() {
      var botao_modal = {
        meta_key: "lgpd_button_theme",
        meta_value: this.selected_botao.value,
      };
      serviceSettings
        .create(botao_modal)
        .then((resp) => {})
        .catch((err) => {
          this.error = true;
        });
    },
    fetch() {
      this.loading = true;
      serviceSettings
        .read("/lgpd")
        .then((resp) => {
          for (let i = 0; i < resp.length; i++) {
            const lgpd = resp[i];
            if (lgpd.meta_key == "lgpd_form_description") {
              this.formulario_lgpd = lgpd.meta_value;
            } else if (lgpd.meta_key == "lgpd_modal_description") {
              this.modal_lgpd = lgpd.meta_value;
            } else if (lgpd.meta_key == "lgpd_modal_theme") {
              this.selected_tema = {
                value: lgpd.meta_value,
                text:
                  lgpd.meta_value == "default"
                    ? "Tema Padrão"
                    : lgpd.meta_value == "dark"
                    ? "Tema Escuro"
                    : lgpd.meta_value == "red"
                    ? "Tema Vermelho"
                    : lgpd.meta_value == "blue"
                    ? "Tema Azul"
                    : lgpd.meta_value == "purple"
                    ? "Tema Roxo"
                    : "Tema Padrão",
              };
            } else if (lgpd.meta_key == "lgpd_button_theme") {
              this.selected_botao = {
                value: lgpd.meta_value,
                text:
                  lgpd.meta_value == "button"
                    ? "Botão unico Continuar e Fechar"
                    : "Botão Continuar e botão Cancelar",
              };
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
#link {
  z-index: 2;
}
#textarea {
  position: absolute;
  left: 0px;
  z-index: 1;
  color: transparent !important;
  border: none !important;
  width: 96.5%;
}
.gap {
  display: grid;
  gap: 25px;
}
.copy {
  cursor: pointer;
}
.acao {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 25px;
}
input {
  width: 100%;
}
.container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: var(--greenn);
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}
.info-checkbox {
  margin: 0;
  padding-top: 5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}
.display-flex {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
</style>
