<template>
  <BaseModal
    name="ModalEditarRemetente"
    id="ModalEditarRemetente"
    idModal="ModalEditarRemetente"
    size="lg"
    title="Editar remetente"
    @shown="filterRemetentes()"
  >
    <div class="grid">
      <div class="email">
        <h4 class="titulo">E-mail</h4>
        <input
          type="email"
          placeholder="Digite seu e-mail"
          v-model="email"
          disabled
        />
      </div>
      <div class="nome">
        <h4 class="titulo">Nome</h4>
        <input
          type="text"
          placeholder="Digite seu nome"
          v-model="nome"
        />
      </div>
      <div class="telefone">
        <h4 class="titulo">Telefone</h4>
        <input
          type="text"
          placeholder="Digite o numero do telefone"
          v-model="phone"
          v-mask="['(##) ####-####', '(##) #####-####']"
          v-validate="'required'"
        />
      </div>
      <div class="site">
        <h4 class="titulo">Site</h4>
        <input
          type="text"
          placeholder="Digite o site"
          v-model="site"
        />
      </div>
      <div class="ocupacao">
        <h4 class="titulo">Ocupação</h4>
        <input
          type="text"
          placeholder="Digite a ocupação"
          v-model="ocupacao"
        />
      </div>
    </div>
    <div v-if="!isSendgrid" style="width: 50%;">
      <div class="feedback">
        <b-form-checkbox
        v-model="feedback"
        name="is_main"
          size="lg"
          switch
          >
        </b-form-checkbox>
        <h4 class="titulo" style="margin-bottom: -4px !important">Feedback por e-mail</h4>
      </div>
      <p style="font-size: 13px;margin-top: 5px; color: #81858e !important;">Receba feedback de e-mails que não foram possíveis serem enviados ou denunciados como spam</p> 
    </div>
      
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>

<script>
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();

export default {
  props:{
    selectedRemetente:Object,
    isSendgrid:Boolean
  },
  data() {
    return {
      id:0,
      nome: "",
      email: "",
      phone: "",
      site: "",
      ocupacao: "",
      loading: false,
      feedback: false
    };
  },
  methods: {
    filterRemetentes(){
      this.id = this.selectedRemetente.id;
      this.nome = this.selectedRemetente.name;
      this.email = this.selectedRemetente.email;
      this.phone = this.selectedRemetente.phone;
      this.site = this.selectedRemetente.site;
      this.ocupacao = this.selectedRemetente.occupation;
      this.getFeedBack();     
    },
    getFeedBack() {
      serviceSettings
      .read({id: `sender/email-feedback?address=${this.email}`})
      .then((resp) => {
        this.feedback = resp.feedback;
      })
    },
    onSubmit() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.nome.length == 0) {
            this.$bvToast.toast("Obrigatório preencher o nome do produto", {
              title: "Cadastro manual",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }
          var data = {
            id: `sender/${this.id}`,
            name: this.nome,
            email: this.email,
            phone: this.phone,
            occupation: this.ocupacao,
            email_feedback_forwarding:this.feedback,
            site: this.site,
          };
          serviceSettings
            .update(data)
            .then((resp) => {
              // console.log(resp)
              this.$bvToast.toast("Atualizado com sucesso!", {
                title: "Criar remetente",
                variant: "primary",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$bvModal.hide("ModalEditarRemetente");
              this.$emit("update");
            })
            .catch((err) => {
              // console.log(err);
              this.$bvToast.toast("Erro ao integrar", {
                title: "Cadastro manual",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.foto {
  margin-top: 10px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 25px;
}
input {
  width: 100%;
}
.flex {
  display: flex;
  gap: 15px;
}
.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.align-feedback{
  margin-bottom: -4px !important;
}
.info-checkbox {
  margin: 0;
  padding-top: 5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}
.feedback{
  display: flex !important;
  align-items: center;
}
</style>
