<template>
  <b-tab
    :title="`Produtos`"
    :active="$route.query.tab === 'produtos'"
    @click="setQuery('produtos')"
    @click.once="fetch()"
  >
    <b-container fluid class="mt-1 wrapper container-user">
      <h5 class="my-3">Configuração de produtos</h5>

      <div class="container-titulo">
        <div>
          <h4 class="titulo">Status do produto</h4>
          <div class="space">
            <b-form-group>
              <multiselect
                v-model="selected_value"
                id="product"
                label="text"
                track-by="value"
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                open-direction="bottom"
                :options="select_option"
                :multiple="false"
                :searchable="false"
                :taggable="false"
                :internal-search="false"
                :clear-on-select="false"
                :close-on-select="true"
                :show-no-results="true"
                :allow-empty="false"
                :hide-selected="false"
                @select="fetch"
              >
                <span slot="noOptions">Pesquise por um status</span>
                <span slot="noResult">Oops! Nenhum status encontrado.</span>
              </multiselect>
            </b-form-group>
          </div>
        </div>
        <div class="botoes">
          <b-col class="container-pesquisa">
            <div class="inputSearch">
              <img src="@/assets/img/icons/search.svg" class="Pointer search" />
              <input
                type="text"
                placeholder="Pesquisar produto"
                @input="debounce"
                v-model="search"
                class="input-busca"
              />
            </div>
          </b-col>
          <BaseButton variant="primary" @click="openModal('ModalCriarProduto')"
            >Novo produto</BaseButton
          >
        </div>
      </div>
      <div class="paginas-conteudo-container">
        <div 
          v-show="!loading"
          class="paginas-conteudo"
          v-for="(produto, index) in produtos"
          :key="index"
          :style="produto.status == 'active' ? '' : 'background-color: #efefef;'"
        >
          <div v-if="!produto.product_photo">
            <img
              src="@/assets/img/icons/img_null.svg"
              alt="Img null"
              class="product-photo"
            />
          </div>
          <div v-else  :style="produto.status == 'active' ? '' : 'filter: grayScale(0.3);'">
            <img
              v-if="produto.product_photo.length > 0"
              :src="produto.product_photo"
              alt="img"
              class="product-photo"
            />
            <img
              v-else
              src="@/assets/img/icons/img_null.svg"
              alt="img"
              class="product-photo"
            />
          </div>
          <div class="product-card">
            <div>
              <h1 class="paginas-descricao-titulo">{{ produto.name }}</h1>
              <p>{{ Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL', useGrouping: true,}).format(produto.product_value) }}</p>
            </div>
            <div>
              <!-- <b-form-checkbox
                v-model="produto.status"
                name="is_main"
                size="lg"
                switch
              >
                <p class="info-checkbox" style="width: 0px">
                  {{ produto.status == 'active' ? " Ativos " : "Inativos" }}
                </p>
              </b-form-checkbox> -->
              <img
                @click="deleteProduto(produto)"
                src="@/assets/img/icons/trash2.svg"
                v-show="produto.status === 'inactive'"
              />
              <svg @click="activateProduct(produto)" v-show="produto.status === 'inactive'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#4ea934" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              <svg v-show="produto.status === 'active'" @click="inactivateProduct(produto)" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="#4ea934" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
              <img
                @click="openModal('ModalEditarProduto', produto)"
                src="@/assets/icons/edit.svg"
              />
            </div>
          </div>
        </div>
        <div v-show="loading" v-for="n in 6" :key="n">
          <b-skeleton
            width="100%"
            height="260px"
            style="border-radius: 10px"
          ></b-skeleton>
        </div>
      </div>
      <div style="height: 350px; align-items: center;" class="d-flex justify-content-center mt-5" v-if="loading">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <ModalCriarProduto @update="fetch()" />
      <ModalEditarProduto
        :produto_selected="produto_selected"
        @update="fetch()"
      />

      <b-row
        v-if="!loading && !produtos.length"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhum produto foi encontrado</p>
      </b-row>
      <b-row  v-show="!loading">
        <b-col>
          <Paginate
            v-if="produtos.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
            :disabled="true"
          />
        </b-col>
      </b-row>
    </b-container>
    <div class="mt-1 container-user wrapper d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
    <img src="../../assets/img/img_null.png" alt="imagem invisível" ref="invisible" v-show="false">
  </b-tab>
</template>

<script>
import ConfigProdutosService from "@/services/resources/ConfigProdutosService";
const serviceProduto = ConfigProdutosService.build();

import ModalCriarProduto from "@/components/Configuracoes/Modal/ModalCriarProduto";
import ModalEditarProduto from "@/components/Configuracoes/Modal/ModalEditarProduto";

import _ from "lodash";

import Paginate from "@/components/Paginate";

import Multiselect from "vue-multiselect";

import Vue from "vue";

export default {
  components: {
    ModalCriarProduto,
    ModalEditarProduto,
    Paginate,
    Multiselect,
  },
  data() {
    return {
      select_option: [
        { value: "", text: "Todos" },
        { value: "active", text: "Ativos" },
        { value: "inactive", text: "Inativos" },
      ],
      selected_value: { value: "", text: "Todos" },
      produtos: [],
      loading: false,
      produto_selected: {},
      search: null,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
    };
  },
  methods: {
    setQuery(tab) {
      this.$router.replace({ query: { tab: tab } });
    },
    openModal(data, produto) {
      this.$bvModal.show(data);
      this.produto_selected = produto;
    },
    setAltImg(event) {
      event.target.src = this.$refs.invisible.src;
    },
    activateProduct(produto){
      produto.status = 'active'
      var data = {
        id: produto.id,
        name: produto.name,
        surname: produto.name,
        status: 'active',
        product_value: produto.product_value,
        product_photo: produto.product_photo,
      };
      serviceProduto
        .update(data)
        .then((resp) => {
          this.$bvToast.toast("Produto ativado com sucesso", {
            title: "Configurações",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          produto.status = 'inactive'
          this.$bvToast.toast("Erro ao ativar produto", {
            title: "Configurações",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
        });
    },
    inactivateProduct(produto){
      produto.status = 'inactive'
      var data = {
        id: produto.id,
        name: produto.name,
        surname: produto.name,
        status: 'inactive',
        product_value: produto.product_value,
        product_photo: produto.product_photo,
      };
      serviceProduto
        .update(data)
        .then((resp) => {
          this.$bvToast.toast("Produto inativado com sucesso", {
            title: "Configurações",
            variant: "info",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch((err) => {
          produto.status = 'active'
          this.$bvToast.toast("Erro ao inativar produto", {
            title: "Configurações",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .finally(() => {
        });
    },
    deleteProduto(produto) {
      Vue.swal({
        title: "Deletar permanentemente",
        text: `Essa ação não poderá ser revertida e apagará todos os registros relacionados. Será como se o produto nunca tivesse existido.`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          let idx = this.produtos.findIndex((item) => item.id == produto.id)
          this.produtos.splice(idx, 1)
          serviceProduto
            .destroy(produto.id)
            .then((resp) => {
              this.$bvToast.toast("Produto deletado com sucesso", {
                title: "Configuração de produtos",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch((err) => {
              this.produtos.splice(idx, 0, produto)
              this.$bvToast.toast("Erro ao deletar produto", {
                title: "Mensagem manual",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    fetch(search = null, e) {
      this.loading = true;
      this.pagination.totalPages = 1;
      let data = {
        id: `list`,
        page: this.pagination.currentPage,
        status: this.selected_value.value,
      };

      // // console.log(data);

      if (this.search) {
        data.search = this.search;
      }
      serviceProduto
        .createId(data)
        .then((resp) => {
          this.produtos = resp.data;
          this.pagination.totalPages = resp.last_page;
          this.pagination.currentPage = resp.current_page;
          this.pagination.perPage = resp.per_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.fetch(this.search);
    }, 500),
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetch(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;
    },
  },
  created() {
    this.selected_value = this.select_option[0];
  },
};
</script>

<style scoped lang="scss">
.space {
  margin-bottom: 15px;
}
.product-photo {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px 5px 0 0;
}
.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 37px;
  top: 17px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.container-pesquisa {
  position: relative;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.search {
  left: 35px;
}
.input-busca {
  width: 100%;
}
.botoes {
  display: flex;
  gap: 10px;
}
.paginas-conteudo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.container-titulo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titulo {
  font-weight: 600;
  font-size: 14px;
}
.descricao-checkbox {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  color: #81858e;
  font-size: 13px;
  max-width: 80%;
  line-height: 1.5;
}
h5 {
  font-weight: 600;
  font-size: 18px;
  color: var(--greenn);
}

.container-user {
  padding: 50px;
}
@media screen and (max-width: 768px) {
  .container-user {
    padding: 30px;
  }
}
.info-checkbox {
  margin: 0;
  padding-top: 5px;
  margin-left: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #81858e;
}
.paginas-menu {
  border-radius: 10px;
  border: none;
}
.margin {
  height: 60px !important;
}
input {
  margin-bottom: 0 !important;
}
.paginas-descricao-opt {
  color: var(--greenn);
}
.paginas-descricao-titulo {
  font-weight: 600;
  font-size: 14px;
  color: var(--gray01);
}
.paginas-conteudo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.paginas-conteudo {
  border: 1px solid #ededf0;
  border-radius: 10px;
  transition: 0.3s;
}
.img-conteudo {
  width: 408px;
  height: 217.42px;
  border-radius: 5px 5px 0 0;
}
.img-icon {
  width: 12px;
  height: 13.5px;
}
.product-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  p {
    font-size: 14px;
    line-height: 1.1;
  }
  > div:last-child {
    display: flex;
    gap: 10px;
    img, svg {
      width: 17px;
      cursor: pointer;
    }
  }
}
.display-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.nova-pagina {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  padding: 20px 40px;
  gap: 10px;
  background: var(--greenn);
  border-radius: 10px;
  border: none;
}
.box-total {
  justify-content: center;
}
.metas {
  list-style: none;
  font-size: 11px;
}
</style>
